<template>
  <v-container mt-flex fluid>
    <v-container d-flex d-sm-none>
      <v-row>
        <v-col cols="12" class="pa-5"> </v-col>
      </v-row>
    </v-container>
    <v-row class="pa-5 bg-white">
      <h1><strong>Unirom </strong>Options</h1>
    </v-row>
    <v-row class="pa-5 bg-white">
      <h1>
        <strong><router-link to="/enrol">Enrol</router-link></strong>
      </h1>
    </v-row>
    <v-row class="pa-5 bg-grey">
      <v-col
        v-for="(iframe, index) in iframes"
        :key="index"
        class="col-sm-12 col-12"
        xs="12"
        md="6"
      >
        <iframe
          :src="iframe.src"
          height="650px"
          width="100%"
          style="text-align: center"
          frameborder="0"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      iframes: [
        {
          src: "https://docs.google.com/document/d/e/2PACX-1vQsKkVVoEfNNlN5wm6uezvqucEO9Au-hOtx3r2pU1qvTOpDriJBhFF5goSwKqqIFPZrekviVIdnpSQ5/pub?embedded=true",
        },
        {
          src: "https://docs.google.com/document/d/e/2PACX-1vSMCVFn6D3SrBc8cj6daEFGavDPZG5cLzZIrbQWhaV-TAbsDFcmMQ-lsMB7NGds7rO1WqIsnrAEUpUG/pub?embedded=true",
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
import jotfom from "../assets/jotform.js";
</script>
<style>
.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left: -100%;
  }

  to {
    opacity: 1;
    margin-left: 0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#fff, 0.8);
}
</style>